import React from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import clsx from 'clsx'
import { v4 as uuid } from 'uuid'
import Router from 'next/router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import modalDuck from 'components/modal/duck'
import { segmentSwatchOverlayViewed } from 'lib/segment'
import {
  SEGMENT_STORAGE_KEY_CATEGORY,
  SEGMENT_STORAGE_KEY_PRODUCT,
  SEGMENT_STORAGE_KEY_SEARCH_ID,
  SEGMENT_STORAGE_KEY_SEARCH_IDX,
} from 'lib/segment/const'
import { appendObjToSessionObj } from 'lib/utils/common/commonUtils'
import Typo from 'constants/typography'
import cartItemsDuck from 'components/cart/cart-items/duck'
import searchModalDuck from 'components/search/search-section/duck'
import BackdropModal from 'components/portal/backdrop-modal'
import Loader from 'components/loader'
import { parseCyKey } from 'lib/utils/cypress'
import { getLocalizedLink } from 'pages/product/utils'
import styles from './style.scss'
import ProductItemFigure from './figure/ProductItemFigure'
import ProductItemPriceSection from './price-section/ProductItemPriceSection'
import ProductItemSwatches from './swatches'
import ProductItemWishlistIcon from './wishlist-icon'
import ProductCollectionBadge from './product-collection-badge'
import HoverInteractionsAddToBag from './figure/hover-interactions/add-to-bag'

const InteractiveSwatch = dynamic(
  () => import('components/product/product-item/interactive-swatch'),
  { ssr: false, loading: Loader },
)

export const LINK_TYPES = {
  pdp: 0,
  similarProducts: 1,
}

class ProductItem extends React.PureComponent {
  wrapperEl = React.createRef()

  state = {
    fullSwatches: null,
    interactiveSwatchData: null,
    selectedSwatch: null,
    showInteractiveSwatchModal: false,
  }

  getIsSoldOut() {
    const { is_sold_out, quantity, stock } = this.props
    const { selectedSwatch } = this.state

    if (selectedSwatch) {
      return selectedSwatch.stock?.is_sold_out
    }

    return quantity <= 0 || is_sold_out || stock?.is_sold_out
  }

  getIsLowStock() {
    const { is_low_stock, quantity, stock } = this.props
    const { selectedSwatch } = this.state

    if (selectedSwatch) {
      return selectedSwatch.stock?.is_low_quantity
    }

    return (
      Boolean(is_low_stock) || quantity <= 5 || Boolean(stock?.is_low_stock)
    )
  }

  getIsBackInStock() {
    const { displayBackInStock, is_back_in_stock } = this.props
    const { selectedSwatch } = this.state

    if (selectedSwatch) {
      return false
    }

    return (
      displayBackInStock &&
      (is_back_in_stock === 1 || is_back_in_stock === true)
    )
  }

  getStockQuantity() {
    const { quantity, stock_quantity, stock } = this.props
    const { selectedSwatch } = this.state

    if (selectedSwatch) {
      return selectedSwatch.stock?.quantity
    }

    return quantity || stock_quantity || stock?.quantity
  }

  getLocation(href) {
    const match = href.match(
      /^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/,
    )

    return (
      match && {
        href,
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7],
      }
    )
  }

  getProductId() {
    const { selectedSwatch } = this.state
    const { product_id, id_product, id_product_2 } = this.props

    if (selectedSwatch) {
      return selectedSwatch.id_product
    }

    return product_id || id_product || id_product_2 || 15411
  }

  getUrlPrefix() {
    const {
      internationalization: { country, language },
    } = this.props

    return `/${country}/${language}/`
  }

  getProductUrl() {
    const {
      category_link_rewrite,
      hyphen_name,
      link_rewrite,
      product_url,
      name,
      internationalization: { language },
    } = this.props
    const { selectedSwatch } = this.state
    const productId = this.getProductId()
    const urlPrefix = this.getUrlPrefix()

    if (selectedSwatch) {
      const {
        category,
        link_rewrite: swatch_link_rewrite,
        name: swatchName,
      } = selectedSwatch
      const categoryLink = category ? category.link_rewrite : 'clothes'
      let swatchLink = swatch_link_rewrite

      // Apply only for TH-TH shop since the product name is translated to the native language
      if (language === 'th') {
        swatchLink = getLocalizedLink(swatchName)
      }

      return `${urlPrefix}${categoryLink}/${productId}-${swatchLink}.html`
    }

    if (product_url) {
      const pathName = this.getLocation(product_url).pathname

      /**
       * /th/en/clothes/12345-abc.html
       * /th/en/clothes/12345-abc/abc/abc.html
       * /th/en/clothes/clothes/12345-abc/abc.html
       * /th/en/clothes/clothes/12345-abc/abc.html
       * /th/clothes/12345-abc.html
       * /th/clothes/12345-abc/abc.html
       * /th/clothes/clothes/12345-abc.html
       * /th/clothes/clothes/12345-abc/abc/abc.html
       */
      const productName = pathName.match(/[0-9]*-.*\.html?/g) // result { 0: '12345-abc/abc/abc.html' }

      if (productName && Object.keys(productName).length > 0) {
        const encodedProductName = productName[0].replace(/\//g, '%2F') // replace all slash with %2F
        const encodedPathName = pathName.replace(
          productName,
          encodedProductName,
        ) // replace it back on full pathName
        return encodedPathName
      }

      // if RegEx is mismatched, return what we got from Algolia
      return pathName
    }

    // url from category page, cart, etc. except Algolia
    const hyphenName = hyphen_name || link_rewrite

    if (hyphenName && typeof hyphenName === 'string') {
      let productNameUrl = hyphenName.replace(/\//g, '%2F')

      // Apply only for TH-TH shop since the product name is translated to the native language
      if (language === 'th') {
        productNameUrl = getLocalizedLink(name)
      }

      if (productId) {
        return `${urlPrefix}${category_link_rewrite}/${productId}-${productNameUrl}.html`
      }

      return `${urlPrefix}clothes/15411-yoshie-piped-obi-belt.html`
    }

    return `${urlPrefix}${category_link_rewrite}/${productId}.html`
  }

  getCoverImgSrc() {
    const {
      full_cover_img_v2,
      flatlayFirst,
      full_flatlay_img_v2,
      image_url,
      images,
    } = this.props
    const { selectedSwatch } = this.state

    if (selectedSwatch) {
      return selectedSwatch.image?.full_path
    }

    if (flatlayFirst && full_flatlay_img_v2) {
      return full_flatlay_img_v2
    }

    return (
      images?.cover?.full_url ||
      images?.full_cover_image_path ||
      full_cover_img_v2 ||
      image_url
    )
  }

  getIs3dBageAvailable() {
    const { is_3d_img_available } = this.props
    const { selectedSwatch } = this.state

    if (selectedSwatch) {
      return selectedSwatch.is_3d_img_available
    }

    return is_3d_img_available
  }

  getHoverImgSrc() {
    const {
      full_cover_img_v2,
      flatlayFirst,
      full_flatlay_img_v2,
      full_hover_img_v2,
      image_url,
      images,
      isPhone,
    } = this.props
    const { selectedSwatch } = this.state

    // Swatch doesn't have hover image yet and we don't need hover for phone
    if (selectedSwatch || isPhone) {
      return null
    }

    if (flatlayFirst && full_cover_img_v2) {
      return full_cover_img_v2 === full_flatlay_img_v2
        ? full_hover_img_v2
        : full_cover_img_v2
    }

    return (
      images?.hover?.full_url ||
      images?.full_hover_image_path ||
      full_hover_img_v2 ||
      image_url
    )
  }

  getDiscountBadge() {
    const {
      displayPrice,
      reduction,
      reduction_formatted,
      price,
      prices,
      showTeaser,
      teaser,
      discounted_price,
    } = this.props

    const { selectedSwatch } = this.state

    if (!displayPrice) {
      return null
    }

    if (
      (teaser || reduction_formatted || prices?.discounted_price) &&
      selectedSwatch
    ) {
      const { reduction: swatchReduction, teaser: swatchTeaser } =
        selectedSwatch

      return (
        swatchReduction?.value_formatted ||
        (showTeaser && swatchTeaser?.reduction_formatted) ||
        null
      )
    }

    if (discounted_price) {
      if (discounted_price !== price) {
        return (
          reduction_formatted ||
          reduction?.reduction_formatted ||
          reduction?.value_formatted
        )
      }

      if (showTeaser && teaser) {
        return teaser?.reduction_formatted
      }

      return null
    }

    // for similar products (if API does not return formatted we don't know)
    if (prices?.reduction) {
      return (
        prices.reduction.reduction_formatted || prices.reduction.value_formatted
      )
    }

    return null // to avoid showing 0 for discount badge
  }

  getPriceFormatted() {
    const {
      price_formatted,
      prices,
      formatted_price,
      original_price_formatted,
    } = this.props
    const { selectedSwatch } = this.state

    if (selectedSwatch && selectedSwatch.prices) {
      return selectedSwatch.prices.original_price_formatted
    }

    if (price_formatted) {
      return price_formatted
    }

    if (formatted_price) {
      return formatted_price
    }

    return prices?.original_price_formatted || original_price_formatted
  }

  getDiscountedPriceFormatted() {
    const {
      discounted_price,
      discounted_price_formatted,
      formatted_discounted_price,
      price,
      prices,
      showTeaser,
      teaser,
    } = this.props
    const { selectedSwatch } = this.state

    if (selectedSwatch && selectedSwatch.prices) {
      if (
        showTeaser &&
        selectedSwatch.teaser &&
        !selectedSwatch.reduction?.value
      ) {
        return selectedSwatch.teaser.discounted_price_formatted
      }

      return selectedSwatch.prices.discounted_price_formatted
    }

    if (discounted_price) {
      if (discounted_price !== price) {
        return (
          discounted_price_formatted ||
          formatted_discounted_price ||
          prices.discounted_price_formatted
        )
      }

      if (showTeaser && teaser) {
        return teaser?.discounted_price_formatted
      }

      return null
    }

    if (prices && prices.original_price > prices.discounted_price) {
      return prices.discounted_price_formatted
    }

    return null
  }

  getIsBeautyProduct() {
    const { default_category, id_category_default } = this.props

    return (
      id_category_default === 1448 ||
      id_category_default === 1214 ||
      default_category?.toLowerCase() === 'beauty' ||
      default_category?.toLowerCase() === 'beet by pomelo'
    )
  }

  getSwatches() {
    const { variations } = this.props

    return variations?.total > 1 ? variations.products : null
  }

  getAddToBagOptions() {
    const { sizes } = this.props
    const { selectedSwatch } = this.state

    if (selectedSwatch) {
      return selectedSwatch?.sizes
    }

    return sizes
  }

  goToSimilarProducts = (e) => {
    this.onLinkClicked(e, LINK_TYPES.similarProducts)
  }

  onLinkClicked = (e, linkType = LINK_TYPES.pdp) => {
    const { closeCrossSellingModal, closeSearchModal, updateCart } = this.props
    let href = ''
    let as = ''

    if (e) {
      if (e.ctrlKey || e.metaKey) {
        return
      }

      if (e?.preventDefault && e?.stopPropagation) {
        e.preventDefault()
        e.stopPropagation()
      }
    }

    const productId = this.getProductId()
    const pageUuid = uuid()

    if (linkType === LINK_TYPES.similarProducts) {
      href = `/similar?id_product=${productId}`
      as = `${this.getUrlPrefix()}similar/${productId}`
      this.prepareSegmentDataCategoryPage()
    } else {
      href = `/product?id=${productId}`
      as = this.getProductUrl()
      this.prepareSegmentDataProductPage(pageUuid)
    }

    href += `&page_uuid=${pageUuid}`

    closeCrossSellingModal()
    updateCart({
      cartOpen: false,
    })
    Router.push(href, as).then(closeSearchModal)
  }

  prepareSegmentDataProductPage(pageUuid) {
    const { idx, refId, refType, subPosition, id_lookbook, lookbook_name } =
      this.props
    const { fullSwatches } = this.state
    let algolia

    if (refType === 'search') {
      algolia = {
        query_id: sessionStorage.getItem(SEGMENT_STORAGE_KEY_SEARCH_ID),
        index: sessionStorage.getItem(SEGMENT_STORAGE_KEY_SEARCH_IDX),
      }
    }

    appendObjToSessionObj(SEGMENT_STORAGE_KEY_PRODUCT.stack, {
      [pageUuid]: {
        click_position: idx,
        ref_id: refId,
        ref_type: refType,
        sub_position: subPosition,
        ...(Boolean(fullSwatches) && { color_swatch: true }),
        ...algolia,
      },
    })
    sessionStorage.setItem(SEGMENT_STORAGE_KEY_PRODUCT.id, refId)
    sessionStorage.setItem(SEGMENT_STORAGE_KEY_PRODUCT.position, idx)
    sessionStorage.setItem(SEGMENT_STORAGE_KEY_PRODUCT.type, refType)
    sessionStorage.setItem(SEGMENT_STORAGE_KEY_PRODUCT.subPos, subPosition)
    if (refType === 'lookbook_shop') {
      sessionStorage.setItem(
        SEGMENT_STORAGE_KEY_PRODUCT.id_lookbook,
        id_lookbook,
      )
      sessionStorage.setItem(
        SEGMENT_STORAGE_KEY_PRODUCT.lookbook_name,
        lookbook_name,
      )
    }

    if (fullSwatches) {
      sessionStorage.setItem(SEGMENT_STORAGE_KEY_PRODUCT.colorSwatch, true)
    }
  }

  prepareSegmentDataCategoryPage() {
    const { idx, subPosition } = this.props

    sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.position, idx)
    sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.subPos, subPosition)
  }

  triggerSwatchModal = () => {
    const {
      category,
      displaySwatches,
      id_product: productId,
      isSearchPage,
      refId,
      refType,
      showTeaser,
      teaser,
      queryId,
      queryIdx,
    } = this.props

    const shouldShowTeaser = teaser && showTeaser

    if (displaySwatches) {
      segmentSwatchOverlayViewed({
        ...(isSearchPage
          ? { query: refId }
          : {
              categoryId: category?.id_category,
              categoryName: category?.link_rewrite,
            }),
        productId,
      })

      this.setState({
        interactiveSwatchData: {
          parentProductId: productId,
          refId,
          refType: refType || 'category',
          showTeaser: shouldShowTeaser,
          queryId,
          queryIdx,
        },
        showInteractiveSwatchModal: true,
      })
    }
  }

  closeModal = (e) => {
    if (e?.preventDefault) {
      e.preventDefault()
    }

    this.setState({ showInteractiveSwatchModal: false })
  }

  setFullSwatches = (fullSwatches) => {
    this.setState({ fullSwatches })
  }

  setSelectedSwatch = (selectedSwatch) => {
    this.setState({ selectedSwatch })
  }

  getSwatchesColor = (swatches, productId) => {
    if (swatches?.length > 0) {
      const colorSwatches = swatches.find(
        (item) => item.id_product === productId,
      )
      return colorSwatches.color
    }
    return null
  }

  renderProductCollectionBadge() {
    const { badge, badge_type, badge_value, collection_name } = this.props
    const { selectedSwatch } = this.state
    const isShowBadge = badge || (badge_type && badge_value)

    if (!isShowBadge) {
      if (collection_name) {
        return (
          <div className="product_collection-name__wrapper">
            <span className={Typo.overline}>{collection_name}</span>
          </div>
        )
      }

      return null
    }

    let isProductBadge = false
    let isCategoryBadge = false
    let swatchBadgeValue = ''

    if (selectedSwatch) {
      const { badge: swatchBadge } = selectedSwatch

      if (swatchBadge?.value) {
        isProductBadge = swatchBadge.type === 'product'
        isCategoryBadge = swatchBadge.type === 'category'
        swatchBadgeValue = swatchBadge.value
      }
    } else {
      isProductBadge =
        (badge?.type === 'product' && badge?.value) ||
        (badge_type === 'product' && badge_value)

      isCategoryBadge =
        (badge?.type === 'category' && badge?.value) ||
        (badge_type === 'category' && badge_value)
    }

    if (isProductBadge || isCategoryBadge) {
      return (
        <ProductCollectionBadge
          badge={badge}
          badge_value={badge_value}
          isProductBadge={isProductBadge}
          isCategoryBadge={isCategoryBadge}
          swatchBadgeValue={swatchBadgeValue}
        />
      )
    }
    return null
  }

  render() {
    const {
      className,
      discounted_price,
      displayBadges,
      displayDescription,
      displayHoverInteractions,
      displayQuickAddToBag,
      displayPremiumTags,
      displayPrice,
      displaySwatches,
      displayWishlistIcon,
      displayFlashSaleTitle,
      id_category_default,
      id_product,
      // is_3d_img_available,
      isPhone,
      isDeleteWishlistProductTimeOut,
      is_on_wishlist,
      is_wishlist,
      is_coming_soon,
      flash_sale_seconds_left,
      is_flash_sale,
      coming_soon_text,
      name,
      noGrid,
      price,
      variations,
      displayManufacturerName,
      product,
      metadata,
      original_price,
      category_link_rewrite,
      promptLocation,
      refType,
      setFirstProductRef,
      setIsDeleteWishlistProductTimeOut,
      setIsSnackBarOpen,
      setUndoClicked,
      undoClicked,
    } = this.props

    const {
      fullSwatches,
      interactiveSwatchData,
      selectedSwatch,
      showInteractiveSwatchModal,
    } = this.state

    const isBeautyProduct = this.getIsBeautyProduct()
    const isSoldOut = this.getIsSoldOut()
    const productId = this.getProductId()
    const productUrl = this.getProductUrl()
    const hoverSrc = this.getHoverImgSrc()
    const swatches = this.getSwatches()
    const wrapperClassNames = clsx('product-item', className, {
      'no-grid': noGrid,
      hoverable: hoverSrc,
    })

    if (setFirstProductRef) {
      setFirstProductRef(this.wrapperEl)
    }

    // create product with color
    let tempPrice = { discounted_price, original_price }
    const allSwatches = variations?.products || null
    if (product?.prices) {
      tempPrice = product.prices
    }
    let productForWishlistIcon = {
      id_product,
      name,
      category: {
        id_category: id_category_default,
        link_rewrite: category_link_rewrite,
      },
      prices: tempPrice,
      metadata: {
        ...metadata,
        tracking: {
          color_name: this.getSwatchesColor(allSwatches, id_product),
        },
      },
      ...(!!product?.manufacturer && { manufacturer: product.manufacturer }),
    }
    if (selectedSwatch) {
      productForWishlistIcon = {
        ...selectedSwatch,
        metadata: {
          ...selectedSwatch.metadata,
          tracking: {
            color_name: selectedSwatch.color?.name,
          },
        },
      }
    }

    const isTablet =
      typeof window !== 'undefined' &&
      window.innerWidth >= 300 &&
      window.innerWidth <= 1024

    const bodyJsx = (
      <React.Fragment>
        <div className="product-item__content-wrapper">
          <div className="product-item-top-section__wrapper">
            <h2 className="product-item-title__wrapper">
              <span
                className={clsx(
                  Typo.subtitle2,
                  'product-item-manufacturer__title',
                )}
                data-cy={parseCyKey(`product_item__title_${productId}`)}
              >
                {displayManufacturerName ? product?.manufacturer?.name : ''}
              </span>
              {displayWishlistIcon && (
                <ProductItemWishlistIcon
                  isDeleteWishlistProductTimeOut={
                    isDeleteWishlistProductTimeOut
                  }
                  isOriginalWishlisted={is_wishlist || is_on_wishlist}
                  productId={productId}
                  product={productForWishlistIcon}
                  selectedSwatch={selectedSwatch}
                  setIsDeleteWishlistProductTimeOut={
                    setIsDeleteWishlistProductTimeOut
                  }
                  setIsSnackBarOpen={setIsSnackBarOpen}
                  setUndoClicked={setUndoClicked}
                  cy={parseCyKey(`product_item__wishlist_${productId}`)}
                  promptLocation={promptLocation}
                  refType={refType}
                  undoClicked={undoClicked}
                />
              )}
            </h2>
          </div>
          <a
            href={productUrl}
            className="product-item-name__clickable"
            onClick={this.onLinkClicked}
            data-cy={parseCyKey(`product_item__description_${productId}`)}
          >
            {displayDescription && (
              <span
                className={clsx('product_description__wrapper', Typo.caption)}
              >
                {selectedSwatch?.name || name}
              </span>
            )}
          </a>
          <div className="product__link__detail">
            <ProductItemPriceSection
              isPhone={isPhone}
              discountedPriceFormatted={this.getDiscountedPriceFormatted()}
              discounted_price={discounted_price}
              displayPrice={displayPrice}
              displayWishlistIcon={displayWishlistIcon}
              id_category_default={id_category_default}
              isOriginalWishlisted={is_wishlist || is_on_wishlist}
              onClick={this.onLinkClicked}
              price={price}
              priceFormatted={this.getPriceFormatted()}
              productId={productId}
              productUrl={productUrl}
              selectedSwatch={selectedSwatch}
              cy={parseCyKey(`product_item__image_${productId}`)}
              isShowFlashSaleIcon={is_flash_sale && !isSoldOut}
              discountBadge={this.getDiscountBadge()}
            />
            {displayBadges && this.renderProductCollectionBadge()}
            {displaySwatches && swatches?.length > 0 && (
              <ProductItemSwatches
                fullSwatches={fullSwatches}
                parentId={id_product}
                selectedSwatchId={selectedSwatch?.id_product || productId}
                setFullSwatches={this.setFullSwatches}
                setSelectedSwatch={this.setSelectedSwatch}
                swatches={swatches}
                total={variations?.total}
                triggerSwatchModal={this.triggerSwatchModal}
                wrapperEl={this.wrapperEl}
              />
            )}
          </div>
        </div>
        {displayHoverInteractions &&
          displayQuickAddToBag &&
          (isPhone || isTablet) && (
            <div className="product-item__add-to-bag">
              <HoverInteractionsAddToBag
                productId={productId}
                refData={{ ref_type: refType }}
                options={this.getAddToBagOptions()}
              />
            </div>
          )}
      </React.Fragment>
    )

    return (
      <div className={wrapperClassNames} ref={this.wrapperEl}>
        <style jsx>{styles}</style>
        <ProductItemFigure
          className={className}
          coverSrc={this.getCoverImgSrc()}
          discountBadge={this.getDiscountBadge()}
          displayHoverInteractions={displayHoverInteractions && !isPhone}
          displayPremiumTags={displayPremiumTags}
          displayFlashSaleTitle={displayFlashSaleTitle}
          goToSimilarProducts={this.goToSimilarProducts}
          hoverSrc={hoverSrc}
          is3dImageAvailable={this.getIs3dBageAvailable()}
          isBackInStock={this.getIsBackInStock()}
          isBeautyProduct={isBeautyProduct}
          isListView
          isLowStock={this.getIsLowStock()}
          isSoldOut={isSoldOut}
          isComingSoon={is_coming_soon}
          isFlashSale={is_flash_sale}
          flash_sale_seconds_left={flash_sale_seconds_left}
          comingSoonText={coming_soon_text}
          name={name}
          onClick={this.onLinkClicked}
          options={this.getAddToBagOptions()}
          productId={productId}
          productUrl={productUrl}
          stockQuantity={this.getStockQuantity()}
          cy={parseCyKey(`product_item__image_${productId}`)}
          refType={refType}
        />
        {bodyJsx}
        {isPhone && displaySwatches && variations?.total > 4 && (
          <BackdropModal isOpen={showInteractiveSwatchModal}>
            <InteractiveSwatch
              close={this.closeModal}
              {...interactiveSwatchData}
            />
          </BackdropModal>
        )}
      </div>
    )
  }
}

ProductItem.defaultProps = {
  badge_type: null,
  badge_value: null,
  category: null,
  className: null,
  collection_name: null,
  full_cover_img_v2: null,
  default_category: null,
  discounted_price: null,
  discounted_price_formatted: null,
  display_color: '',
  displayBackInStock: true,
  displayBadges: true,
  displayDescription: null,
  displayHoverInteractions: false,
  displayQuickAddToBag: false,
  displayManufacturerName: false,
  displayPremiumTags: true,
  displayPrice: true,
  displaySwatches: false,
  displayWishlistIcon: null,
  displayFlashSaleTitle: true,
  full_flatlay_img_v2: null,
  flatlayFirst: false,
  formatted_discounted_price: null,
  formatted_price: null,
  full_hover_img_v2: undefined,
  hyphen_name: null,
  id_category_default: null,
  id_product: null,
  id_product_2: null,
  image_url: null,
  is_low_stock: null,
  is_on_wishlist: null,
  idx: '',
  isSearchPage: false,
  is_sold_out: false,
  flash_sale_seconds_left: 0,
  is_flash_sale: false,
  is_coming_soon: false,
  coming_soon_text: '',
  is_wishlist: null,
  link_rewrite: null,
  metadata: {},
  name: null,
  noGrid: null,
  original_price: null,
  original_price_formatted: null,
  pattern_filename: '',
  price: null,
  prices: null,
  price_formatted: null,
  product_id: null,
  product_url: null,
  promptLocation: undefined,
  quantity: undefined,
  queryId: undefined,
  queryIdx: undefined,
  reduction: undefined,
  reduction_formatted: '',
  refId: '',
  refType: '',
  id_lookbook: undefined,
  lookbook_name: undefined,
  setFirstProductRef: undefined,
  sizes: null,
  showTeaser: false,
  subPosition: '',
  teaser: null,
  stock: null,
  stock_quantity: null,
}

ProductItem.propTypes = {
  badge: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  badge_type: PropTypes.string,
  badge_value: PropTypes.string,
  category: PropTypes.shape({
    id_category: PropTypes.number,
    link_rewrite: PropTypes.string,
  }),
  category_link_rewrite: PropTypes.string.isRequired,
  className: PropTypes.string,
  closeCrossSellingModal: PropTypes.func.isRequired,
  collection_name: PropTypes.string,
  full_cover_img_v2: PropTypes.string,
  default_category: PropTypes.string,
  discounted_price: PropTypes.number,
  discounted_price_formatted: PropTypes.string,
  display_color: PropTypes.string,
  displayBackInStock: PropTypes.bool,
  displayBadges: PropTypes.bool,
  displayDescription: PropTypes.bool,
  displayHoverInteractions: PropTypes.bool,
  displayQuickAddToBag: PropTypes.bool,
  displayPremiumTags: PropTypes.bool,
  displayPrice: PropTypes.bool,
  displayWishlistIcon: PropTypes.bool,
  displaySwatches: PropTypes.bool,
  displayFlashSaleTitle: PropTypes.bool,
  flatlayFirst: PropTypes.bool,
  full_flatlay_img_v2: PropTypes.string,
  formatted_discounted_price: PropTypes.string,
  formatted_price: PropTypes.string,
  full_hover_img_v2: PropTypes.string,
  hyphen_name: PropTypes.string,
  id_category_default: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  id_product: PropTypes.number,
  id_product_2: PropTypes.number,
  idx: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  image_url: PropTypes.string,
  images: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      cover: PropTypes.shape({
        id_image: PropTypes.number.isRequired,
        full_url: PropTypes.string,
      }),
      hover: PropTypes.shape({
        id_image: PropTypes.number,
        full_url: PropTypes.string,
      }),
    }),
    PropTypes.shape({
      full_cover_image_path: PropTypes.string,
      full_hover_image_path: PropTypes.string,
    }),
  ]).isRequired,
  internationalization: PropTypes.shape({
    country: PropTypes.string,
    language: PropTypes.string,
  }).isRequired,
  isDeleteWishlistProductTimeOut: PropTypes.bool.isRequired,
  isSearchPage: PropTypes.bool,
  is_3d_img_available: PropTypes.bool.isRequired,
  isPhone: PropTypes.bool.isRequired,
  is_back_in_stock: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
    .isRequired,
  is_low_stock: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  is_on_wishlist: PropTypes.bool,
  is_sold_out: PropTypes.bool,
  flash_sale_seconds_left: PropTypes.number,
  is_flash_sale: PropTypes.bool,
  is_coming_soon: PropTypes.bool,
  coming_soon_text: PropTypes.string,
  is_wishlist: PropTypes.bool,
  link_rewrite: PropTypes.string,
  name: PropTypes.string,
  noGrid: PropTypes.bool,
  original_price: PropTypes.number,
  original_price_formatted: PropTypes.string,
  pattern_filename: PropTypes.string,
  price: PropTypes.number,
  price_formatted: PropTypes.string,
  prices: PropTypes.shape({
    original_price: PropTypes.number.isRequired,
    original_price_formatted: PropTypes.string.isRequired,
    discounted_price: PropTypes.number,
    discounted_price_formatted: PropTypes.string,
    reduction: PropTypes.shape({
      reduction_formatted: PropTypes.string,
      value_formatted: PropTypes.string,
    }),
  }),
  product: PropTypes.shape({
    name: PropTypes.string,
    id_product: PropTypes.number,
    id_category_default: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    manufacturer: PropTypes.shape({
      name: PropTypes.string,
    }),
    prices: PropTypes.shape({}),
    sizes: PropTypes.arrayOf(
      PropTypes.shape({
        size: PropTypes.string,
        id_product_attribute: PropTypes.number,
        stock: PropTypes.shape({
          quantity: PropTypes.number,
          is_low_stock: PropTypes.bool,
          is_sold_out: PropTypes.bool,
        }),
      }),
    ),
  }).isRequired,
  product_id: PropTypes.number,
  product_url: PropTypes.string,
  quantity: PropTypes.number,
  reduction: PropTypes.oneOfType([
    PropTypes.shape({
      reduction_formatted: PropTypes.string,
      value_formatted: PropTypes.string,
    }),
    PropTypes.number,
  ]),
  reduction_formatted: PropTypes.string,
  refId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  refType: PropTypes.string,
  id_lookbook: PropTypes.number,
  lookbook_name: PropTypes.string,
  setIsDeleteWishlistProductTimeOut: PropTypes.func.isRequired,
  setIsSnackBarOpen: PropTypes.func.isRequired,
  setFirstProductRef: PropTypes.func,
  setUndoClicked: PropTypes.func.isRequired,
  undoClicked: PropTypes.bool.isRequired,
  sizes: PropTypes.arrayOf(PropTypes.shape({})),
  showTeaser: PropTypes.bool,
  stock: PropTypes.shape({
    quantity: PropTypes.number,
    is_low_stock: PropTypes.bool,
    is_sold_out: PropTypes.bool,
  }),
  stock_quantity: PropTypes.number,
  subPosition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  teaser: PropTypes.shape({
    discounted_price_formatted: PropTypes.string,
    reduction_formatted: PropTypes.string,
  }),
  closeSearchModal: PropTypes.func.isRequired,
  variations: PropTypes.shape({
    total: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  displayManufacturerName: PropTypes.bool,
  queryId: PropTypes.string,
  queryIdx: PropTypes.string,
  metadata: PropTypes.shape({}),
  promptLocation: PropTypes.string,
  updateCart: PropTypes.func.isRequired,
}

export { ProductItem }

export default connect(
  (state) => ({
    isPhone: state.device.isPhone,
    internationalization: state.internationalization,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        closeCrossSellingModal: () =>
          modalDuck.creators.toggleCartNotice(false),
        closeSearchModal: searchModalDuck.creators.closeSearchModal,
        updateCart: cartItemsDuck.creators.update,
      },
      dispatch,
    ),
)(ProductItem)
