import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Loader from 'components/loader'
import { customFlagValue } from 'lib/utils/handle-flag/const'
import { useFlagsmithGetFlagValue } from 'lib/flagsmith'
import WishlistSnackbar from 'components/wishlist/wishlist-snackbar'
import EmptyProductSection from './empty-product'
import MapProductItem from './map-product-item'
import styles from './style.scss'

const ProductList = ({
  category,
  className,
  displayBadges,
  displayDescription,
  displayHoverInteractions,
  displayQuickAddToBag,
  displayManufacturerName,
  displayPrice,
  displaySwatches,
  displayWishlistIcon,
  flatlayFirst,
  isFilterAvailable,
  isPhone,
  isSearchPage,
  noGrid,
  list: productList,
  onProductPageChanged,
  pageName,
  productsPerPage,
  promptLocation,
  refId,
  refType,
  id_lookbook,
  lookbook_name,
  showTwoPerRow,
  showTeaser,
}) => {
  const [filteredProducts, setFilteredProducts] = useState(productList)
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
  const [isDeleteWishlistProductTimeOut, setIsDeleteWishlistProductTimeOut] =
    useState(false)
  const [undoClicked, setUndoClicked] = useState(false)

  const shouldShowOOSProducts = useFlagsmithGetFlagValue(
    'ShowOutOfStockInSearchResult_20230515',
    true,
  )

  useEffect(() => {
    setFilteredProducts(() =>
      !isSearchPage || shouldShowOOSProducts
        ? productList
        : productList.filter(
            (product) =>
              !product.stock?.is_sold_out ||
              product.stock?.is_sold_out === false,
          ),
    )
  }, [shouldShowOOSProducts, productList, isSearchPage, setFilteredProducts])

  const hoolahFlagValue = customFlagValue({ defaultValue: false }) // TODO: handle with flagsmith ('InstallmentsPreCheckout-20220627' is old flag name)

  if (!filteredProducts) {
    return (
      <div className="loader">
        <style jsx>{styles}</style>
        <Loader />
      </div>
    )
  }

  const totalProducts = filteredProducts.length
  if (filteredProducts.length === 0) {
    return (
      <EmptyProductSection
        isFilterAvailable={isFilterAvailable}
        isSearchPage={isSearchPage}
      />
    )
  }

  const isCategoryPage = pageName === 'category'
  const onlyCategoryNotSearchPage = isCategoryPage && !isSearchPage
  const shouldExtendMargin = !hoolahFlagValue && onlyCategoryNotSearchPage

  const baseHoolahRule = hoolahFlagValue && onlyCategoryNotSearchPage
  const hoolahDesktopRule = Boolean(baseHoolahRule && !isPhone)
  const hoolahMobileRule = Boolean(baseHoolahRule && isPhone)
  const isHoolahWithSingleProduct = Boolean(
    baseHoolahRule && totalProducts === 1,
  )

  const isNoGrid = !!totalProducts && noGrid

  // Hoolah banner should be at the bottom when
  //   - the product is available
  //   - the product is less than 11 or equal to 1
  //   - It is the mobile web.
  const shouldHoolahBeAtBottom =
    totalProducts > 0 &&
    hoolahMobileRule &&
    (totalProducts < 11 || totalProducts === 1)

  const productItemProps = (product, idx) => ({
    key: product.id_product,
    className,
    hoolahDesktopRule,
    hoolahMobileRule,
    idx,
    onProductPageChanged,
    product,
    productProps: {
      category,
      displayBadges,
      displayDescription,
      displayHoverInteractions,
      displayQuickAddToBag,
      displayPrice,
      displayManufacturerName,
      displaySwatches,
      displayWishlistIcon,
      flatlayFirst,
      idx: idx + 1,
      isDeleteWishlistProductTimeOut,
      key: product.id_product,
      noGrid,
      product,
      promptLocation,
      refId,
      refType,
      id_lookbook,
      lookbook_name,
      showTeaser,
      setIsDeleteWishlistProductTimeOut,
      setIsSnackBarOpen,
      setUndoClicked,
      undoClicked,
      is_flash_sale: product?.is_flash_sale || product?.prices?.is_flash_sale,
      flash_sale_seconds_left:
        product?.flash_sale_seconds_left ||
        product?.prices?.flash_sale_seconds_left,
      ...product,
    },
    productsPerPage,
    isHoolahWithSingleProduct,
    totalProducts,
  })

  return (
    <React.Fragment>
      <div
        className={clsx('product-list', {
          'no-grid': isNoGrid,
          'recommend-items': showTwoPerRow,
          'hoolah-banner-bottom': shouldHoolahBeAtBottom,
          'extend-bottom-margin': shouldExtendMargin,
        })}
      >
        <style jsx>{styles}</style>

        {filteredProducts.map((product, idx) => (
          <MapProductItem {...productItemProps(product, idx)} />
        ))}
      </div>
      <WishlistSnackbar
        isProductList
        isSnackBarOpen={isSnackBarOpen}
        onCloseSnackBar={() => {
          setIsDeleteWishlistProductTimeOut(true)
          setIsSnackBarOpen(false)
        }}
        undoDeleteWishlist={() => {
          setUndoClicked(true)
          setIsDeleteWishlistProductTimeOut(false)
          setIsSnackBarOpen(false)
        }}
      />
    </React.Fragment>
  )
}

ProductList.defaultProps = {
  category: null,
  className: '',
  displayBadges: true,
  displayDescription: false,
  displayHoverInteractions: false,
  displayQuickAddToBag: false,
  displayManufacturerName: true,
  displayPrice: true,
  displaySwatches: false,
  displayWishlistIcon: false,
  flatlayFirst: false,
  isFilterAvailable: false,
  isSearchPage: false,
  list: [],
  noGrid: false,
  onProductPageChanged: null,
  pageName: '',
  productsPerPage: 18,
  promptLocation: undefined,
  id_lookbook: undefined,
  lookbook_name: undefined,
  showTwoPerRow: false,
  showTeaser: false,
}

ProductList.propTypes = {
  category: PropTypes.shape({
    total_products: PropTypes.number,
  }),
  className: PropTypes.string,
  displayBadges: PropTypes.bool,
  displayDescription: PropTypes.bool,
  displayHoverInteractions: PropTypes.bool,
  displayQuickAddToBag: PropTypes.bool,
  displayManufacturerName: PropTypes.bool,
  displayPrice: PropTypes.bool,
  displaySwatches: PropTypes.bool,
  displayWishlistIcon: PropTypes.bool,
  flatlayFirst: PropTypes.bool,
  isFilterAvailable: PropTypes.bool,
  isPhone: PropTypes.bool.isRequired,
  isSearchPage: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  noGrid: PropTypes.bool,
  onProductPageChanged: PropTypes.func,
  pageName: PropTypes.string,
  productsPerPage: PropTypes.number,
  promptLocation: PropTypes.string,
  refId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  refType: PropTypes.string.isRequired,
  id_lookbook: PropTypes.number,
  lookbook_name: PropTypes.string,
  showTwoPerRow: PropTypes.bool,
  showTeaser: PropTypes.bool,
}

export default connect((state) => ({
  internationalization: state.internationalization,
  isPhone: state.device.isPhone,
}))(ProductList)
